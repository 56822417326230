.floating-button {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
  }
  
  .floating-button:hover {
    background-color: #000000;
    text-decoration: none;
  }
  
  .whatsapp-icon {
    font-size: 28px;
  }
  